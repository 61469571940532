// Overwrite variables
$primary: #374d5d;

// Import default Bootstrap
@import '~bootstrap/scss/bootstrap.scss';

// html,
// body,
// #root,
// .App {
//   height: 100%;
// }
#root {
  display: flex;
  flex-direction: column;
}

// hint border for outputtext
.border-output-hint {
  border: 3px solid #17a2b8;
}

.bg-dark-custom {
  background-color: #54758e;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white;
  background-color: #374d5d;
}

.grey-bg {
  background-color: #444444;
}

.yellow-bg {
  background-color: #ffd966;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: auto;
}

.imagePreview {
  position: relative;
  width: 200px;
}

.imagePreview img {
  width: 100%;
}

.imagePreview button {
  position: absolute;
  right: 0;
  top: 0;
}

form>* {
  margin: 10px 0;
}

img:not(.editor-image img) {
  max-width: 400px !important;
  margin: auto;
}

.editor-tableCell {
  vertical-align: top !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-row {
  padding-left: 5px;
  padding-right: 5px;
}

.help-link {
  cursor: pointer;
  text-decoration: underline;
}

.intro-function-text {
  padding: 10px;
}

.show-newlines {
  white-space: pre-line;
}

// Glossary

.pointer {
  cursor: pointer;
}

.attachOnRight {
  position: absolute;
  top: 15px;
  right: 10px;
}

.copyButton {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  color: #374d5d;
}

.editButton,
.saveButton {
  position: absolute;
  right: 35px;
  bottom: 8px;
  cursor: pointer;
  color: #374d5d;
}

.editButton {
  color: '#374D5D';
}

.textEditCancelButton {
  position: absolute;
  right: 55px;
  bottom: 6px;
  cursor: pointer;
  color: #374d5d !important;
}

.deleteButton {
  cursor: pointer;
  display: inline-block;
}

.copyButtonTranslation {
  position: absolute;
  right: 20px;
  bottom: 10px;
  cursor: pointer;
  color: #969696;
}

.copyButtonTranslation:hover {
  color: #4d4d4d;
  transition: 0.1s;
}

.text-left {
  text-align: left;
}

// tools bar

.ml-1 {
  margin-left: 0.5rem;
}

.label-height {
  height: 22px;
}

// Tags styling at TranslationHistory.js

.feedback-select-option.negative {
  background: #dc3545 !important;
  color: white;
}

.feedback-select-option.positive {
  background: #28a745 !important;
  color: white;
}

.feedback-select-option.adjusted {
  background: #374d5d !important;
  color: white;
}

.glossarySearch {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.whatsNewBtn {
  position: absolute;
  right: 0;
  margin: 10px;
  color: #374d5d;
  border: 1px solid #374d5d;
}

.translation-table-header {
  padding: 17px !important;
}

.users-table-row:hover {
  cursor: pointer;
  background-color: rgba(55, 77, 93, 0.486) !important;
}

.required {
  border: 1px solid red;
}

.required-text {
  font-weight: normal;
}

.whats-new-content {
  margin-top: 50px;
}

.orderform-labels {
  text-align: left;
}

.orderform-labels input,
.orderform-labels select {
  border: 1px solid rgba($color: #000000, $alpha: 0.59);
  background: #f8f9fa;
  border-radius: 10px;
}

.orderform-labels label {
  font-weight: 500;
  font-size: 15px;
}

.margintop-20 {
  margin-top: 20px;
}

.marginbottom-24 {
  margin-bottom: 24px;
}

.tooltip-inner {
  max-width: 30rem;
}

.feedbackContainer {
  margin-top: 1.8rem !important;
  text-align: start;
}

.link-button {
  background-color: transparent;
  border: none;
  color: #374d5d;
  cursor: pointer;
  // text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover {
  text-decoration: underline;
}

.alert-danger {
  //   padding-right: 1em;
  text-wrap: wrap;
}

::placeholder {
  color: #6c757d !important;
  font-size: 1rem;
  filter: blur(0px) !important;
}

::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6c757d !important;
  font-size: 1rem;
  filter: blur(0px) !important;
}

::-moz-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d !important;
  font-size: 1rem;
  filter: blur(0px) !important;
}

::-webkit-input-placeholder {
  /* Microsoft Edge */
  color: #6c757d !important;
  font-size: 1rem;
  filter: blur(0px) !important;
}

div[role='alert'] {
  text-wrap: wrap;
}

// Buttons should not be uppercase by default
.MuiButton-root {
  text-transform: none !important;
}

.MuiButtonBase-root {
  text-transform: none !important;
}