.helpButton {
  position: absolute;
  bottom: 2rem;
  right: 0;
}

.baseContainer {
  margin: auto;
  min-width: 90%;
}

.productDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.productCard {
  width: 80%;
}

.accountHeading {
  font-weight: bold;
}

.toggleDiv {
  width: 100%;
  padding: 0 15px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 7px;
}
