/* overriding mui styles */
.css-o943dk-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: inherit !important;
}
.css-156qn3s-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background-color: #e3e9ed !important;
  color: #374d5d !important;
}
.css-156qn3s-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before,
.css-156qn3s-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after {
  border-bottom: 0 !important;
}
.css-156qn3s-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root {
  background-color: #e3e9ed !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  background-color: #e3e9ed !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected,
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected:hover {
  background-color: #374d5d !important;
  color: #ffffff !important;
}
.GlossaryField_glossary-explanations__16kSr .card-header {
  margin-bottom: 0px !important;
}

.deleteModal .modal-content {
  background-color: #374d5d !important;
  color: #ffffff;
}
.deleteModal .modal-footer {
  border-top: none !important;
  justify-content: center !important;
}
.deleteModal .btn-danger,
.deleteModal .btn-danger:hover {
  color: #dee2e6 !important;
  border: solid 2px #dee2e6 !important;
  border-radius: 5px !important;
  background-color: #374d5d !important;
  margin-right: 15px !important;
}
.deleteModal .btn:focus {
  box-shadow: none !important;
}

.deleteModal .btn-primary,
.deleteModal .btn-primary {
  color: #374d5d !important;
  border: solid 2px #e3e9ed !important;
  border-radius: 5px !important;
  background-color: #e3e9ed !important;
}

.card-header .btn-link:hover {
  text-decoration: none;
}
.card-header .btn-link:focus {
  text-decoration: none;
  box-shadow: none;
}
#formGlossarEntry\.explanation {
  resize: none !important;
}
.newEntryCard input::placeholder,
.newEntryCard textarea::placeholder {
  color: #abb5be;
}

.explanationEditTextArea {
  resize: none !important;
  border: 0px;
}
.explanationText {
  margin-bottom: 2rem;
}
.textEditAreaGroup {
  margin-top: -0.75rem;
  margin-left: -0.5rem;
  margin-right: -0.75rem;
}
