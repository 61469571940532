.info-text {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
}

.info-text > span {
  text-align: left;
  margin: 10px 0px;
}

#infotext-header {
  margin-top: 0px;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
}

.info-infoicon {
  min-width: 50px;
}

.info-div {
  width: 100%;
  background-color: #f8f9fa;
  border: 1px solid #374d5d;
  display: flex;
  padding: 15px 0px;
}
