.translation-textarea-infobox .tooltip-inner {
  width: 30rem;
  max-width: 30rem !important;
  padding: 10px;
  font-size: 12px;
  font-family: 'Roboto';
}

.language-label {
  margin-bottom: 0px !important;
}

.output-dropdown {
  width: 100% !important;
  padding: 0px;
  height: 24px;
  font-size: 1rem;
}

.output-textbox {
  padding: 15px 40px 25px 25px !important;
  max-height: 70vh;
  min-height: 24px;
}

.tooltip-col {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.separator-col {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

/* Setting style */
.setting-container {
  padding: 1.5em;
  position: sticky;
  top: 0;
  background: #fff;
  height: 100%;
  color:'#374d5d';
  bottom: 0;
  overflow: auto;
  z-index: 2000;
}
/* .setting-description {
  color: #374d5d;
} */

.highlighted {
  background-color: red;
  color: #fff;
}
