.clear-text-icon {
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  color: #969696;
  transition: 0.1s;
}

.clear-text-icon:hover {
  color: #4d4d4d;
  transition: 0.1s;
}

.input-synonyms {
  padding-right: 30px;
}

.load-synonyms-button {
  margin-top: 0;
  filter: blur(0px) !important;
}
