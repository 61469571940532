.separator {
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    border-bottom: 2px solid #000;
  }