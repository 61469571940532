.productCardHeader {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-bottom: 0px;
}

.productCardRecommended {
  width: 146px;
  height: 30px;
  position: absolute;
  left: -41px;
  top: 18px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.rotated45degNeg {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #232A35;
}

.product-title {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.productCardBody {
  padding-top: 0px;
}

.productCardHeaderStrike {
  font-size: 15px;
  font-weight: 500;
}

.productCardSelect {
  border-radius: 10px;
}

.moneyDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  min-height: 130px;
}

.product-card-header {
  font-size: 40px;
  font-weight: bold;
}

.product-feature-list {
  list-style: none;
  padding-inline-start: 0 !important;
  min-height: 300px;
}

.product-feature-list>li {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  min-height: 4rem;
  text-align: left;
  flex: 1;
}

.product-feature-list__item {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.product-feature-list>li:nth-child(1) {
  min-height: 4.4rem;
}

.product-feature-list__item>span:nth-child(2) {
  margin-left: 1vw;
}

.selectionpage-productcard__base {
  border-radius: 25px;
  border: 1px solid #232A35;
}

.productcard__recomended {
  outline: 5px solid #232A35;
  background-color: #f8f9fa;
}