.clear-text-icon {
  position: absolute;
  right: 17px;
  top: 17px;
  cursor: pointer;
  color: #969696;
  transition: 0.1s;
}

.language-label {
  margin-bottom: 0px !important;
}

.clear-text-icon:hover {
  color: #4d4d4d;
  transition: 0.1s;
}

.input-textarea {
  padding: 15px 40px 25px 25px;
  max-height: 70vh;
  min-height: 30px;
}

.tooltip-col {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}

.translation-textarea-infobox .tooltip-inner {
  width: 40rem !important;
  max-width: 40rem !important;
  padding: 10px;
  font-size: 12px;
  font-family: 'Roboto';
}
