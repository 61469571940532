.list-unstyled {
  margin-bottom: 0;
}

.drop-menu {
  padding: 0;
}

.drop-item {
  text-align: left;
  padding: 10px;
}

.drop-item:active {
  background-color: #E8ECEE !important;
  color: #232A35 !important
}

.dropdown-menu {
  min-width: fit-content;
}

.dropdown-item:hover {
  border-radius: 5px;
}


.select-drop-down,
.select-drop-down:hover,
.select-drop-down:focus {
  color: #fff;
  text-decoration: none;

}