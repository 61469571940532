.logout-item {
  background-color: #f8f9fa !important;
  color: #374d5d !important;
  width: 100% !important;
  border: none;
}

.logout-item:hover {
  background-color: #f8f9fa !important;
  color: #1b252d !important;
  border: none;
  outline: none;
}
