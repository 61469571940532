.edit-container {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.save-button {
  margin-top: 1.2em;
}

.glossary-explanation-cell {
  overflow: auto;
}

.form-control-glossary,
.form-control-glossary:focus {
  resize: none;
  border-radius: 0 !important;
  box-shadow: none;
}