.intro-function-text {
  padding: 10px;
}

.show-newlines {
  white-space: pre-line;
}

.glossary-source {
  bottom: 10px;
  font-size: 10px;
  left: 10px;
  color: #999999;
}

.glossarysubHead {
  /* font-family: 'Inter'; */
  /* font-style: normal; */
  font-weight: 400;
  /* font-size: 16px; */
  color: #646464;

  /* position: absolute;
  width: 100px;
  height: 10px;
  left: 18px;
  top: 436px; */
}

.tagNotSelected {
  font-weight: regular;
}

.tagSelected {
  font-weight: bold;
}

.glossary-explanations {
  margin-bottom: 0px;
  /* border-radius: 0.25rem !important; */
}

.glossary-header-button button {
  width: 90%;
  text-align: left;
}

.glossary-header-button:hover {
  background-color: #e3e9ed;
}

.tags-dropdown {
  margin: 10px 10px 17px 0px !important;
  width: 100% !important;
}

.glossary-card-body {
  padding-bottom: 0.25rem;
}

.tags-input-container {
  /* border: 1px solid #cfcfcf; */
  /* padding: 0.5em; */
  border-radius: 3px;
  width: 100%;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  overflow: hidden;
  /* overflow-y: scroll; */
  max-height: 75%;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  outline: none;
  width: 100%;
  color: #495057;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.headerBox {
  display: flex;
  justify-content: space-between;
  /* margin-top: 30px; */
}

.exportLink {
  color: #646464 !important;
  font-size: 12px;
}

.exportIcon {
  margin-bottom: 5px;
}