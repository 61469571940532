.tags-input-container {
  border: 1px solid #cfcfcf;
  padding: 0.5em;
  border-radius: 3px;
  width: calc(min(80vw, 600px));
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5em;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 75%;
}

.tag-item {
  background-color: rgb(218, 216, 216);
  display: inline-block;
  padding: 0.5em 0.75em;
  border-radius: 20px;
}

.tag-item .close {
  height: 20px;
  width: 20px;
  background-color: rgb(48, 48, 48);
  color: #fff;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5em;
  font-size: 18px;
  cursor: pointer;
}

.tags-input {
  flex-grow: 1;
  padding: 0.5em 0;
  border: none;
  outline: none;
}

.otherCategory-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.edit-container {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;

}

.save-button {
  margin-top: 5px;
}

.form-control:disabled {
  background-color: #e9ecef !important;
}

#input-history,
#output-history {
  text-wrap: wrap;
}

.history-actions-header {
  padding-left: 40px !important;
}