.forgot-pwd-send {
  width: 110px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.forgot-pwd-div {
  display: flex;
}

.forgot-pwd-input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
