.flexContainer {
  display: flex;
  margin-bottom: 30px;
}

.sourceButton {
  background-color: white !important;
  color: #374d5d !important;
  border-color: white !important;
  /* border-color: #cdd8e0 !important; */
  border-radius: 4px !important;
  display: flex !important;
  align-items: center !important;
  margin-right: 10px !important;
  padding: 3px !important;
  min-width: 36px !important;
  min-height: 36px !important;
}

.flowStepButton {
  height: 50px;
  padding-right: 15px !important;
}

.active {
  border-bottom: 4px solid #edc347 !important;
  background-color: #ffffff !important;
  /* color: #232A35 !important; */
}

.inactive {
  opacity: 0.7;
  pointer-events: none;
}

.sourceButtonIcon {
  width: 50px;
  height: 30px;
}

.bottomText {
  font-size: 10px;
}
