.header-infodiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.info-div {
  display: flex;
  flex-direction: column-reverse;
}

.header-infoicon {
  margin: 0 20px;
}

.header-infodiv > span {
  width: 460px;
  line-height: 23px;
  text-align: left;
}

.productcard-base {
  border-radius: 25px;
  border: 1px solid #374d5d;
}

.productcard-col {
  font-size: 15px;
  min-width: 300px;
}

.infoside-col {
  display: flex;
  flex-direction: column-reverse;
}

.container {
  min-width: 100%;
  padding: 0 20px;
  margin: 0;
}

.productcard-recommended {
  width: 146px;
  height: 30px;
  position: absolute;
  left: -41px;
  top: 18px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.productcard-header {
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-bottom: 0px;
}

.rotated-45deg-neg {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-color: #374d5d;
}

.productcard-recomended {
  outline: 5px solid #374d5d;
  background-color: #f8f9fa;
}

.productcard-moneydiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  min-height: 130px;
}

.product-card-header {
  font-size: 40px;
  font-weight: bold;
}

.product-card-header-strike {
  font-size: 15px;
  font-weight: 500;
}

.productcard-body {
  padding-top: 0px;
}

.productcard-select {
  border-radius: 10px;
}

.toggle-gift-div {
  flex: 1;
  padding-left: 30px;
  display: flex;
  cursor: pointer;
}

.toggle-gift {
  background-color: #f8f9fa;
  display: flex;
  border: 1px dashed #374d5d;
  justify-content: center;
  align-items: center;
}

.toggle-gift-text {
  width: 140px;
  height: 34px;
}

.toggle-gifticon {
  color: #374d5d;
}

.toggle-gift-icon {
  width: 34px;
  height: 34px;
}

.toggle-gift > span {
  font-weight: bold;
}

.header-togglediv {
  display: flex;
  margin-top: 20px;
}
