.linkStyle {
    text-decoration: none;
}

.linkStyle:hover {
    text-decoration: underline;
}

body {
    font-family: sans-serif !important;
}