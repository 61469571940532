.forgot-password {
  padding: 5px 0;
  color: #374d5d;
}

.forgot-password:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #444444;
}
